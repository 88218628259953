<template>
	<div class="ori-publish">
		<ori-header />
		<div class="top">
			<div class="title">
				20年口碑服务，20万程序员、100+专业软件开发公司，专注解决各类软件开发需求
			</div>
			<div class="select contailer">
				<el-input placeholder="请输入关键字搜索" class="input-with-select">
					<el-select v-model="value" placeholder="请选择" slot="prepend">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
					<el-button slot="append"><i class="el-icon-search"></i> 立即搜索</el-button>
				</el-input>
			</div>
			<div class="demand contailer">
				<ul>
					<li
						@click="activeDemand = 1"
						:class="{ activeDemand: activeDemand === 1 }"
					>
						<i class="el-icon-document"></i> 技术需求
					</li>
					<li
						@click="activeDemand = 2"
						:class="{ activeDemand: activeDemand === 2 }"
					>
						<i class="el-icon-office-building"></i> 产业需求
					</li>
					<li
						@click="activeDemand = 3"
						:class="{ activeDemand: activeDemand === 3 }"
					>
						<i class="el-icon-user"></i> 人才需求
					</li>
					<li
						@click="activeDemand = 4"
						:class="{ activeDemand: activeDemand === 4 }"
					>
						<i class="el-icon-money"></i> 投融资需求
					</li>
				</ul>
			</div>
			<div class="demo contailer">
				<div class="demo-right" v-if="activeDemand === 1">
					<div class="dr-top">
						<ul>
							<li>
								<div class="dr-text" @click="activeDr = 1">
									<span :class="{ activeDr: activeDr === 1 }">1</span>
									<p>选择项目类型</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeDr = 2">
									<span :class="{ activeDr: activeDr === 2 }">2</span>
									<p>项目描述</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeDr = 3">
									<span :class="{ activeDr: activeDr === 3 }">3</span>
									<p>技能要求</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeDr = 4">
									<span :class="{ activeDr: activeDr === 4 }">4</span>
									<p>工期与预算</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeDr = 5">
									<span :class="{ activeDr: activeDr === 5 }">5</span>
									<p>联系方式</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="dr-content" v-if="activeDr === 1">
						<div class="change">
							<ul>
								<li
									:class="{ activeChange: activeChange === 1 }"
									@click="activeChange = 1"
								>
									视频图像类
								</li>
								<li
									:class="{ activeChange: activeChange === 2 }"
									@click="activeChange = 2"
								>
									虚拟现实类
								</li>
								<li
									:class="{ activeChange: activeChange === 3 }"
									@click="activeChange = 3"
								>
									人工智能类
								</li>
								<li
									:class="{ activeChange: activeChange === 4 }"
									@click="activeChange = 4"
								>
									电商应用类
								</li>
								<li
									:class="{ activeChange: activeChange === 5 }"
									@click="activeChange = 5"
								>
									游戏娱乐类
								</li>
								<li
									:class="{ activeChange: activeChange === 6 }"
									@click="activeChange = 6"
								>
									办公管理类
								</li>
								<li
									:class="{ activeChange: activeChange === 7 }"
									@click="activeChange = 7"
								>
									金融交易类
								</li>
								<li
									:class="{ activeChange: activeChange === 8 }"
									@click="activeChange = 8"
								>
									物联网应用类
								</li>
								<li
									:class="{ activeChange: activeChange === 9 }"
									@click="activeChange = 9"
								>
									工具支持类
								</li>
								<li
									:class="{ activeChange: activeChange === 10 }"
									@click="activeChange = 10"
								>
									底层研发类
								</li>
								<li
									:class="{ activeChange: activeChange === 11 }"
									@click="activeChange = 11"
								>
									嵌入式开发类
								</li>
								<li
									:class="{ activeChange: activeChange === 12 }"
									@click="activeChange = 12"
								>
									视图设计类
								</li>
								<li
									:class="{ activeChange: activeChange === 13 }"
									@click="activeChange = 13"
								>
									其他项目类
								</li>
							</ul>
						</div>
						<h4>需支持/适配的客户端（支持多选）</h4>
						<el-checkbox-group v-model="checkList">
							<el-checkbox label="网站"></el-checkbox>
							<el-checkbox label="桌面软件"></el-checkbox>
							<el-checkbox label="小程序"></el-checkbox>
							<el-checkbox label="微信公众号"></el-checkbox>
							<el-checkbox label="H5页面"></el-checkbox>
							<el-checkbox label="IOS"></el-checkbox>
							<el-checkbox label="安卓"></el-checkbox>
							<el-checkbox label="其他形式"></el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="dr-content" v-if="activeDr === 2">
						<el-form
							:model="ruleForm"
							:rules="rules"
							:inline="true"
							label-width="100px"
							label-position="right"
						>
							<el-form-item prop="title" label="标题:">
								<el-input
									placeholder="请输入项目标题"
									v-model="ruleForm.title"
									class="input-with-select"
								>
								</el-input>
							</el-form-item>
							<el-form-item prop="classify" label="分类:">
								<el-select v-model="ruleForm.classify" placeholder="请选择分类">
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item prop="desc" label="项目描述:">
								<el-input
									type="textarea"
									v-model="ruleForm.desc"
									placeholder="请输入项目描述..."
									:autosize="{ minRows: 6, maxRows: 10 }"
								></el-input>
							</el-form-item>
							<el-form-item prop="phone" label="手机号:">
								<el-input
									v-model="ruleForm.phone"
									placeholder="请输入手机号"
								></el-input>
							</el-form-item>
							<el-form-item>
								<sliding />
							</el-form-item>
						</el-form>
					</div>
					<div class="dr-content" v-if="activeDr === 3">
						<div class="requirements">
							<span>身份要求:</span>
							<el-radio-group v-model="identityRadio">
								<el-radio :label="1">不限</el-radio>
								<el-radio :label="2">个人</el-radio>
								<el-radio :label="3">企业</el-radio>
							</el-radio-group>
						</div>
						<div class="requirements">
							<span>驻场要求:</span>
							<el-radio-group v-model="siteRadio">
								<el-radio :label="1">无需驻场</el-radio>
								<el-radio :label="2">偶尔到场交流</el-radio>
								<el-radio :label="3">定期驻场</el-radio>
								<el-radio :label="3">全程驻场</el-radio>
							</el-radio-group>
						</div>
						<div class="requirements">
							<span>地域要求:</span>
							<v-distpicker @selected="sel"></v-distpicker>
						</div>
						<div class="requirements">
							<el-select
								v-model="skillsRequired"
								filterable
								multiple
								placeholder="请选择或输入需求所需的技能，如您不清楚应选择哪些技能，请留空，由竞标方为您提供所需的技能信息"
							>
								<el-option-group
									v-for="group in skillsRequiredList"
									:key="group.label"
									:label="group.label"
								>
									<el-option
										v-for="item in group.options"
										:key="item.skillsRequired"
										:label="item.label"
										:value="item.skillsRequired"
									>
									</el-option>
								</el-option-group>
							</el-select>
						</div>
					</div>
					<div class="dr-content" v-if="activeDr === 4">
						<div class="content">
							<div>
								<strong>工期:</strong>
								<el-input
									placeholder="您期望多长时间完成, 默认: 可商议"
									v-model="projectLimit"
								>
									<template slot="append">
										<span
											@click="activeLimit = 1"
											:class="{ activeLimit: activeLimit === 1 }"
											>天</span
										>
										<span
											@click="activeLimit = 2"
											:class="{ activeLimit: activeLimit === 2 }"
											>月</span
										>
									</template>
								</el-input>
							</div>
							<div>
								<strong>预算:</strong>
								<el-select v-model="budget" placeholder="请选择">
									<el-option
										v-for="item in budgetList"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</div>
							<div>
								<strong>汇总:</strong>
								<el-input
									type="textarea"
									:autosize="{ minRows: 4, maxRows: 6 }"
									placeholder="请输入您想要的工期与预算"
									v-model="textareaBudget"
								>
								</el-input>
							</div>
						</div>
					</div>
					<div class="dr-content" v-if="activeDr === 5">
						<div class="content">
							<el-form
								ref="form"
								:model="contact"
								label-width="80px"
								:rules="rules"
							>
								<el-form-item prop="name" label="姓名">
									<el-input
										v-model="contact.name"
										placeholder="请输入姓名"
									></el-input>
								</el-form-item>
								<el-form-item prop="phone" label="电话">
									<el-input
										v-model="contact.phone"
										placeholder="请输入手机/电话号码"
									></el-input>
								</el-form-item>
							</el-form>
						</div>
						<div class="dr-footer">
							<el-checkbox v-model="checked"></el-checkbox>
							<span>我同意遵守</span>
							<span><a href="#">《软件项目交易网协议》</a></span>
							<button>立即发布</button>
						</div>
					</div>
				</div>
				<div class="demo-right" v-if="activeDemand === 2">
					快速发布产业需求
					<div class="dr-footer">
						<el-checkbox v-model="checked"></el-checkbox>
						<span>我同意遵守</span>
						<span><a href="#">《软件项目交易网协议》</a></span>
						<button>立即发布</button>
					</div>
				</div>
				<div class="demo-right" v-if="activeDemand === 3">
				<div class="dr-top">
						<ul>
							<li>
								<div class="dr-text" @click="activeTalentDemand = 1">
									<span :class="{ activeDr: activeTalentDemand === 1 }">1</span>
									<p>选择项目类型</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeTalentDemand = 2">
									<span :class="{ activeDr: activeTalentDemand === 2 }">2</span>
									<p>职业基本信息</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeTalentDemand = 3">
									<span :class="{ activeDr: activeTalentDemand === 3 }">3</span>
									<p>职位要求</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="dr-content" v-if="activeTalentDemand === 1">
						<div class="change">
							<ul>
								<li
									:class="{ activeChange: activeChange === 1 }"
									@click="activeChange = 1"
								>
									视频图像类
								</li>
								<li
									:class="{ activeChange: activeChange === 2 }"
									@click="activeChange = 2"
								>
									虚拟现实类
								</li>
								<li
									:class="{ activeChange: activeChange === 3 }"
									@click="activeChange = 3"
								>
									人工智能类
								</li>
								<li
									:class="{ activeChange: activeChange === 4 }"
									@click="activeChange = 4"
								>
									电商应用类
								</li>
								<li
									:class="{ activeChange: activeChange === 5 }"
									@click="activeChange = 5"
								>
									游戏娱乐类
								</li>
								<li
									:class="{ activeChange: activeChange === 6 }"
									@click="activeChange = 6"
								>
									办公管理类
								</li>
								<li
									:class="{ activeChange: activeChange === 7 }"
									@click="activeChange = 7"
								>
									金融交易类
								</li>
								<li
									:class="{ activeChange: activeChange === 8 }"
									@click="activeChange = 8"
								>
									物联网应用类
								</li>
								<li
									:class="{ activeChange: activeChange === 9 }"
									@click="activeChange = 9"
								>
									工具支持类
								</li>
								<li
									:class="{ activeChange: activeChange === 10 }"
									@click="activeChange = 10"
								>
									底层研发类
								</li>
								<li
									:class="{ activeChange: activeChange === 11 }"
									@click="activeChange = 11"
								>
									嵌入式开发类
								</li>
								<li
									:class="{ activeChange: activeChange === 12 }"
									@click="activeChange = 12"
								>
									视图设计类
								</li>
								<li
									:class="{ activeChange: activeChange === 13 }"
									@click="activeChange = 13"
								>
									其他项目类
								</li>
							</ul>
						</div>
					</div>
					<div class="dr-content" v-if="activeTalentDemand === 2">
						<el-form label-width="100px" label-position="right" size="medium">
							<el-form-item label="招聘类型：">
								<el-input style="width: 80%" />
							</el-form-item>

							<el-form-item label="职位名称：">
								<el-input style="width: 80%" />
							</el-form-item>

							<el-form-item label="职位描述：">
								<el-input
									:autosize="{ minRows: 2, maxRows: 4 }"
									type="textarea"
									style="width: 80%"
								/>
							</el-form-item>

							<el-form-item label="工作地点：">
								<el-input style="width: 80%" />
							</el-form-item>

							<el-form-item label="职位类型：">
								<el-input style="width: 80%" />
							</el-form-item>
						</el-form>
					</div>
					<div class="dr-content" v-if="activeTalentDemand === 3">
						<el-form label-width="100px" label-position="right" size="medium">
							<el-form-item label="经验和学历：">
								<el-select
									v-model="value"
									placeholder="请选择"
									style="width: 130px"
								>
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
								<el-select
									v-model="value"
									placeholder="请选择"
									style="width: 130px; margin: 0 15px"
								>
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="薪资范围：">
								<div>
									<el-select
										v-model="value"
										placeholder="请选择"
										style="width: 130px"
									>
										<el-option
											v-for="item in options"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
									<el-select
										v-model="value"
										placeholder="请选择"
										style="width: 130px; margin: 0 15px"
									>
										<el-option
											v-for="item in options"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
									<span>X</span>
									<el-select
										v-model="value"
										placeholder="请选择"
										style="width: 130px; margin: 0 15px"
									>
										<el-option
											v-for="item in options"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
								</div>
								<div class="tip">*请选择月薪</div>
							</el-form-item>

							<el-form-item label="职位关键词：">
								<el-select
									v-model="value"
									style="width: 80%"
									multiple
									placeholder="请选择"
								>
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item>
								<div class="text-right" style="width: 83%">
									简历将发送到 00000@outlook.com
									，如需更改邮箱，请在【个人中心】中修改
								</div>
							</el-form-item>
						</el-form>
						<div class="dr-footer">
							<el-checkbox v-model="checked"></el-checkbox>
							<span>我同意遵守</span>
							<span><a href="#">《软件项目交易网协议》</a></span>
							<button>立即发布</button>
						</div>
					</div>
				</div>
				<div class="demo-right" v-if="activeDemand === 4">
          <div class="dr-top">
						<ul>
							<li>
								<div class="dr-text" @click="activeInvestmentDemand = 1">
									<span :class="{ activeDr: activeInvestmentDemand === 1 }">1</span>
									<p>基本信息</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeInvestmentDemand = 2">
									<span :class="{ activeDr: activeInvestmentDemand === 2 }">2</span>
									<p>其他资料</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeInvestmentDemand = 3">
									<span :class="{ activeDr: activeInvestmentDemand === 3 }">3</span>
									<p>融资信息</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeInvestmentDemand = 4">
									<span :class="{ activeDr: activeInvestmentDemand === 4 }">4</span>
									<p>融资计划</p>
								</div>
							</li>
							<li>
								<i class="el-icon-right"></i>
							</li>
							<li>
								<div class="dr-text" @click="activeInvestmentDemand = 5">
									<span :class="{ activeDr: activeInvestmentDemand === 5 }">5</span>
									<p>项目亮点</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="dr-content" v-if="activeInvestmentDemand === 1">
						<el-form label-width="100px" label-position="right" size="medium">
							<el-form-item label="项目名称：">
								<el-input style="width: 80%" placeholder="50字以内，如：xxx" />
							</el-form-item>

							<el-form-item label="一句话简介：">
								<el-input
									style="width: 80%"
									placeholder="请用一句话描述介绍您的项目，50字以内。尽量简洁有力"
									type="textarea"
									:autosize="{ minRows: 2, maxRows: 4 }"
								/>
							</el-form-item>

							<el-form-item label="行业/赛道：">
								<el-input style="width: 80%" placeholder="50字以内，如：xxx" />
							</el-form-item>

							<el-form-item label="简要描述：">
								<el-input
									style="width: 80%"
									placeholder="尽可能简洁的说明产品定位、服务对象以及商业模式。500字内"
									type="textarea"
									:autosize="{ minRows: 6, maxRows: 10 }"
								/>
							</el-form-item>
              <el-form-item><el-input style="width:80%" placeholder="jin"></el-input></el-form-item>
						</el-form>
					</div>
					<div class="dr-content" v-if="activeInvestmentDemand === 2">
						<el-form label-width="100px" label-position="right" size="medium">
							<el-form-item label="企业主体：">
								<el-input
									style="width: 80%"
									placeholder="请填写所属企业的工商名称"
								/>
							</el-form-item>

							<el-form-item label="总部所在地：">
								<div style="width: 80%; display: flex">
									<div style="width: 40%; margin-right: 15px">
										<el-input placeholder="请填写所属企业的工商名称" />
									</div>
									<div style="width: 60%">
										<el-input placeholder="请填写所属企业的工商名称" />
									</div>
								</div>
							</el-form-item>

							<el-form-item label="项目网址：">
								<el-input style="width: 80%" placeholder="https://" />
							</el-form-item>

							<el-form-item label="微信公众号：">
								<el-input
									style="width: 80%"
									placeholder="输入公众号名称或ID，没有可不填。但项目网址和微信公众号至少填写一个"
								/>
							</el-form-item>
						</el-form>
					</div>
					<div class="dr-content" v-if="activeInvestmentDemand === 3">
						<el-form label-width="120px" label-position="right" size="medium">
							<el-form-item label="本轮融资轮次：">
								<el-select
									style="width: 80%"
									v-model="value"
									placeholder="请选择"
								>
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="本轮融资金额：">
								<div style="width: 80%; display: flex">
									<el-input
										style="width: 90%"
										placeholder="填写融资金额，并选择货币单位"
									/>
									<el-select
										style="width: 30%"
										v-model="moneyValue"
										placeholder="请选择"
									>
										<el-option
											v-for="item in money"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
								</div>
							</el-form-item>

							<el-form-item label="本轮投资方：">
								<el-input
									style="width: 80%"
									placeholder="如有多家，请用逗号分隔"
								/>
							</el-form-item>

							<el-form-item label="融资完成时间：">
								<el-date-picker
									style="width: 80%"
									v-model="value1"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-form>
					</div>
					<div class="dr-content" v-if="activeInvestmentDemand === 4">
						<el-form label-width="120px" label-position="right" size="medium">
							<el-form-item
								label="是否正在寻求下一轮融资："
								label-width="180px"
							>
								<el-radio-group v-model="value" style="width: 80%">
									<el-radio label="1">是，寻求融资</el-radio>
									<el-radio label="2">否，暂不需要</el-radio>
								</el-radio-group>
							</el-form-item>

							<el-form-item label="本轮融资轮次：">
								<el-select
									style="width: 80%"
									v-model="value"
									placeholder="请选择"
								>
									<el-option
										v-for="item in options"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="本轮融资金额：">
								<div style="width: 80%; display: flex">
									<el-input
										style="width: 90%"
										placeholder="填写融资金额，并选择货币单位"
									/>
									<el-select
										style="width: 30%"
										v-model="moneyValue"
										placeholder="请选择"
									>
										<el-option
											v-for="item in money"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div class="dr-content" v-if="activeInvestmentDemand === 5">
						<el-form label-width="120px" label-position="right" size="medium">
							<el-form-item label="发展数据：">
								<el-input
									type="textarea"
									style="width: 80%"
									placeholder="请简述项目发展阶段的各方面数据，包括但不限于商业或用户数据。500字以内"
									:autosize="{ minRows: 4, maxRows: 6 }"
								/>
							</el-form-item>

							<el-form-item label="竞争壁垒：">
								<el-input
									type="textarea"
									style="width: 80%"
									placeholder="请简述项目的护城河，或者已经建立的竞争壁垒。500字以内"
									:autosize="{ minRows: 4, maxRows: 6 }"
								/>
							</el-form-item>

							<el-form-item label="团队构成：">
								<el-input
									type="textarea"
									style="width: 80%"
									placeholder="如果团队背景对该项目有明显推动作用，请说明具体优势。500字以内"
									:autosize="{ minRows: 4, maxRows: 6 }"
								/>
							</el-form-item>
						</el-form>
						<div class="dr-footer">
							<el-checkbox v-model="checked"></el-checkbox>
							<span>我同意遵守</span>
							<span><a href="#">《软件项目交易网协议》</a></span>
							<button>立即发布</button>
						</div>
					</div>
				</div>
				<div class="demo-left">
					<div class="dl-top" v-if="activeDemand === 1">
						<span><i class="el-icon-document"></i>最新技术需求</span>
						<span
							><ul>
								<li></li>
								<li></li>
								<li></li></ul
						></span>
					</div>
					<div class="dl-top" v-if="activeDemand === 2">
						<span><i class="el-icon-document"></i>最新产业需求</span>
						<span
							><ul>
								<li></li>
								<li></li>
								<li></li></ul
						></span>
					</div>
					<div class="dl-top" v-if="activeDemand === 3">
						<span><i class="el-icon-user"></i>最新人才需求</span>
						<span
							><ul>
								<li></li>
								<li></li>
								<li></li></ul
						></span>
					</div>
					<div class="dl-top" v-if="activeDemand === 4">
						<span><i class="el-icon-money"></i>最新投融资需求 </span>
						<span
							><ul>
								<li></li>
								<li></li>
								<li></li></ul
						></span>
					</div>
					<div class="dl-content">
						<ul>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
							<li>
								<span>找类似云中医源码成品</span>
								<span>待商议</span>
								<span><i class="el-icon-user-solid"></i> 3</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="cat contailer">
				<ul>
					<li>
						<a href="#"
							><img src="@/assets/images/public/wechat.svg" alt="" />
							<p>微信开发</p></a
						>
					</li>
					<li>
						<a href="#"
							><img src="@/assets/images/public/ui-design.svg" alt="" />
							<p>UI设计</p></a
						>
					</li>
					<li>
						<a href="#"
							><img src="@/assets/images/public/web-design.svg" alt="" />
							<p>网站开发</p></a
						>
					</li>
					<li>
						<a href="#"
							><img src="@/assets/images/public/app-design.svg" alt="" />
							<p>APP开发</p></a
						>
					</li>
					<li>
						<a href="#"
							><img
								src="@/assets/images/public/desktop-application.svg"
								alt=""
							/>
							<p>桌面应用</p></a
						>
					</li>
					<li>
						<a href="#"
							><img src="@/assets/images/public/management-system.svg" alt="" />
							<p>管理系统</p></a
						>
					</li>
					<li>
						<a href="#"
							><img src="@/assets/images/public/data.svg" alt="" />
							<p>数据采集与分析</p></a
						>
					</li>
					<li>
						<a href="#"
							><img src="@/assets/images/public/hardware.svg" alt="" />
							<p>物联网</p></a
						>
					</li>
				</ul>
			</div>
		</div>
		<div class="excellent_recommend_title"><h3>优秀服务推荐</h3></div>
		<div class="see-more">查看更多 <i class="el-icon-caret-right"></i></div>
		<div class="service contailer">
			<div class="left">
				<ul>
					<li @mousemove="active = 1" :class="{ active: active === 1 }">
						<img src="@/assets/images/public/iot.svg" alt="" /><span
							>物联网</span
						>
					</li>
					<li @mousemove="active = 2" :class="{ active: active === 2 }">
						<img src="@/assets/images/public/web.png" alt="" /><span
							>网站开发</span
						>
					</li>
					<li @mousemove="active = 3" :class="{ active: active === 3 }">
						<img src="@/assets/images/public/app.png" alt="" /><span
							>app开发</span
						>
					</li>
					<li @mousemove="active = 4" :class="{ active: active === 4 }">
						<img
							src="@/assets/images/public/system-management.png"
							alt=""
						/><span>管理系统</span>
					</li>
				</ul>
			</div>
			<div class="right" v-if="active === 1">
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
			</div>
			<div class="right" v-if="active === 2">
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
			</div>
			<div class="right" v-if="active === 3">
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
			</div>
			<div class="right" v-if="active === 4">
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
				<div class="item">
					<p class="clearFix">
						定制_智能_[物联网]_网关_开发定制_智能_[物联网]_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发_网关_开发
					</p>
					<div class="see-detail">
						查看详情
					</div>
					<div class="message">
						<img src="@/assets/images/public/header.jpg" alt="" />
						<span>物联网iot定制开发</span>
					</div>
				</div>
			</div>
		</div>
		<div class="demo-all">
			<div class="demo-detail contailer">
				<div class="hd">
					<ul>
						<li @click="activeHd = 1" :class="{ activeHd: activeHd === 1 }">
							<i class="el-icon-date"></i> 最新发布
						</li>
						<li @click="activeHd = 2" :class="{ activeHd: activeHd === 2 }">
							<i class="el-icon-attract"></i> 开发中
						</li>
						<li @click="activeHd = 3" :class="{ activeHd: activeHd === 3 }">
							<i class="el-icon-trophy"></i> 优秀软件服务商
						</li>
					</ul>
				</div>
				<div v-if="activeDemand === 1">
					<div class="bd" v-if="activeHd === 1">
						<div class="bd-item" v-for="(i, index) in 10" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 2">
						<div class="bd-item" v-for="(i, index) in 15" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in list" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">{{ item.type }}</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">{{ item.type }}</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 3">
						<div class="bd-item" v-for="(i, index) in 5" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeDemand === 2">
					<div class="bd" v-if="activeHd === 1">
						<div class="bd-item" v-for="(i, index) in 10" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 2">
						<div class="bd-item" v-for="(i, index) in 15" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in list" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">{{ item.type }}</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">{{ item.type }}</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 3">
						<div class="bd-item" v-for="(i, index) in 5" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeDemand === 3">
					<div class="bd" v-if="activeHd === 1">
						<div class="bd-item" v-for="(i, index) in 10" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 2">
						<div class="bd-item" v-for="(i, index) in 15" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in list" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">{{ item.type }}</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">{{ item.type }}</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 3">
						<div class="bd-item" v-for="(i, index) in 5" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeDemand === 4">
					<div class="bd" v-if="activeHd === 1">
						<div class="bd-item" v-for="(i, index) in 10" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 2">
						<div class="bd-item" v-for="(i, index) in 15" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in list" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">{{ item.type }}</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">{{ item.type }}</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bd" v-if="activeHd === 3">
						<div class="bd-item" v-for="(i, index) in 5" :key="index">
							<div class="bd-item-box">
								<div
									class="bd-left"
									@click="$router.push('ori-publish-detail')"
								>
									<img src="@/assets/images/public/u3883.png" alt="" />
									<div class="number">7-12k</div>
								</div>
								<div class="detail" @click="$router.push('ori-publish-detail')">
									<div class="detail-top">
										<span>李聪</span>
										<span>刚刚活跃</span>
									</div>
									<div class="detail-midder">
										<span>30岁</span>
										<span>|</span>
										<span>10年</span>
										<span>|</span>
										<span>大专</span>
										<span>|</span>
										<span>在职-考虑机会</span>
									</div>
									<div class="detail-footer">
										<span>求职期望:</span>
										<span>深圳商务专员</span>
									</div>
									<div class="detail-bottom">
										为人随和为人随和为人随和为人随和为人随和为人随和为人随和为人随和
									</div>
								</div>
								<div
									class="bd-time"
									@click="$router.push('ori-publish-detail')"
								>
									<el-steps direction="vertical">
										<el-step
											icon="el-icon-price-tag"
											description="2006.08-2009.03 2006.08-2009.03 2006.08-2009.03"
										></el-step>
										<el-step
											icon="el-icon-discount"
											description="2006-2009"
										></el-step>
									</el-steps>
								</div>
								<div
									class="bd-right"
									@click="$router.push('ori-publish-detail')"
								>
									<p>聚焦网路新公司聚焦网路新公司聚焦网路新公司</p>
									<p>聚焦网路新公司</p>
									<p>聚焦网路新公司聚焦网路</p>
									<p>聚焦网路新公</p>
								</div>
								<div class="bd-btn" @click="sayHello(index)">
									打招呼
								</div>
							</div>
							<div class="bd-sayHello-box" ref="sayHelloBox">
								<div class="box-top">
									<span>沟通职务: 政府关系</span>
									<span @click="sayHelloNone(index)"
										><i class="el-icon-close"></i
									></span>
								</div>
								<div class="box-content">
									<div class="content-top">
										<span class="box-name">李聪</span>
										<span class="box-infomation">
											<ul>
												<li>30岁</li>
												<li>|</li>
												<li>10年</li>
												<li>|</li>
												<li>大专</li>
												<li>|</li>
												<li>深圳</li>
												<li>|</li>
												<li>7-12k</li>
											</ul>
										</span>
									</div>
									<h4>
										此牛人近日被开聊较多，是否让您的消息<a href="#"
											>优先提醒他</a
										>?
									</h4>
									<div class="inner">
										<div class="chat-list" ref="list">
											<div v-for="(item, index) in 10" :key="index">
												<!-- 左侧是机器人 -->
												<div class="chat-item left">
													<div class="chat-pao">1111111111111111</div>
												</div>

												<!-- 右侧是当前用户 -->
												<div class="chat-item right">
													<div class="chat-pao my">22222222222222222</div>
												</div>
											</div>
										</div>
										<div class="reply-container van-hairline--top">
											<el-input
												placeholder="请输入内容"
												v-model="sayHelloInput"
											>
												<template slot="append"
													><span>
														提交
													</span></template
												>
											</el-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<page-foot />
	</div>
</template>

<script>
import PageFoot from '../../../components/ori-com/PageFoot.vue'
import sliding from './sliding.vue'
import VDistpicker from 'v-distpicker'
export default {
	components: { PageFoot, sliding, VDistpicker },
	data() {
		return {
			options: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			value: '',
			ruleForm: {
				//项目描述
				title: '',
				classify: '',
				desc: '',
				phone: '',
				name: '',
			},
			contact: {
				//联系方式
				name: '',
				phone: '',
			},
			rules: {
				title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
				classify: [
					{ required: true, message: '分类不能为空', trigger: 'blur' },
				],
				desc: [
					{ required: true, message: '项目描述不能为空', trigger: 'blur' },
					{ min: 30, message: '项目描述不能少于30字', trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '手机号不能为空', trigger: 'blur' },
					{ min: 11, max: 11, message: '手机号格式不对', trigger: 'blur' },
				],
				name: [
					{ required: true, message: '请输入联系人姓名', trigger: 'blur' },
				],
			},
			checked: false,
			active: 1,
			activeHd: 1,
			activeDr: 1,
			activeChange: 1, //项目类型
			activeDemand: 1, //需求
			activeTalentDemand: 1, //人才需求
			activeInvestmentDemand: 1, //投融资需求
			checkList: [],
			identityRadio: 1, //身份要求
			siteRadio: 1, //驻场要求
			citydata: '', //城市
			projectLimit: '', //工期
			activeLimit: 1, //天/月
			skillsRequiredList: [
				//技能要求
				{
					label: '开发语言',
					options: [
						{
							skillsRequired: 'Shanghai',
							label: '上海',
						},
						{
							skillsRequired: 'Beijing',
							label: '北京',
						},
					],
				},
				{
					label: '技能与框架',
					options: [
						{
							skillsRequired: 'Chengdu',
							label: '成都',
						},
						{
							skillsRequired: 'Shenzhen',
							label: '深圳',
						},
						{
							skillsRequired: 'Guangzhou',
							label: '广州',
						},
						{
							skillsRequired: 'Dalian',
							label: '大连',
						},
					],
				},
				{
					label: '容器与中间件',
					options: [
						{
							skillsRequired: 'Chengdu',
							label: '成都',
						},
						{
							skillsRequired: 'Shenzhen',
							label: '深圳',
						},
						{
							skillsRequired: 'Guangzhou',
							label: '广州',
						},
						{
							skillsRequired: 'Dalian',
							label: '大连',
						},
					],
				},
			],
			skillsRequired: '', //技能要求
			budgetList: [
				{
					value: '选项1',
					label: '可议价',
				},
				{
					value: '选项2',
					label: '5000以下',
				},
				{
					value: '选项3',
					label: '5000-10000',
				},
				{
					value: '选项4',
					label: '10000-50000',
				},
				{
					value: '选项5',
					label: '50000-100000',
				},
				{
					value: '选项6',
					label: '1000000-500000',
				},
				{
					value: '选项7',
					label: '500000以上',
				},
			],
			budget: '', //预算
			textareaBudget: '', // 输入的工期与预算
			money: [
				{
					value: '选项1',
					label: '人民币',
				},
				{
					value: '选项2',
					label: '美元',
				},
			],
			moneyValue: '选项1',
			value1: '',
			list: [
				{
					type: '11111111111111111',
				},
				{
					type: '11111111111111111',
				},
				{
					type: '11111111111111111',
				},
			],
			sayHelloInput: '',
			activeSayHello: true,
		}
	},
	methods: {
		sel(data) {
			this.citydata =
				data.province.value + ' ' + data.city.value + ' ' + data.area.value
			console.log(this.citydata)
		},
		sayHello(index) {
			if (this.activeSayHello) {
				this.$refs.sayHelloBox[index].style.display = 'block'
				this.activeSayHello = false
			} else {
				this.$refs.sayHelloBox[index].style.display = 'none'
				this.activeSayHello = true
			}
		},
		sayHelloNone(index) {
			this.$refs.sayHelloBox[index].style.display = 'none'
		},
	},
}
</script>

<style lang="scss" scoped>
.ori-publish {
	background-color: #f7f7f7;
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
		}
	}
	a {
		color: #0a58ca;
	}
	.clearFix {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	.top {
		background-color: #4fafc2;
		.title {
			padding: 20px 0;
			text-align: center;
			font-size: 25px;
		}
		.select {
			padding: 20px;
			border-radius: 5px;
			background: #4fafc2;
			margin-bottom: 20px;
			background-color: rgba(255, 255, 255, 0.15);
			.el-select {
				width: 150px;
			}
			.el-button {
				width: 150px;
				background: #2eb135;
				color: #fff;
			}
		}
		.demand {
			background-color: #fff;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom: 1px solid #d7d7d7;
			ul {
				display: flex;
				li {
					display: inline-block;
					width: 120px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					padding: 0 5px;
					cursor: pointer;
				}
				li:nth-child(1) {
					border-top-left-radius: 5px;
				}
				.activeDemand {
					background-color: #2eb135;
					color: #fff;
				}
			}
		}
		.demo {
			background-color: #fff;
			display: flex;
			padding: 20px 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			.demo-left {
				flex: 4;
				// background: yellow;
				.dl-top {
					margin: 0 20px;
					font-size: 18px;
					border-bottom: 1px solid #d7d7d7;
					display: flex;
					justify-content: space-between;
					ul {
						li {
							display: inline-block;
							width: 10px;
							height: 10px;
							background: #c5c5c5;
							border-radius: 10px;
							margin: 10px 5px;
							&:first-child {
								width: 30px;
								background-color: #2eb135;
							}
						}
					}
				}
				.dl-content {
					ul {
						li {
							border-bottom: 1px solid #d7d7d7;
							display: flex;
							justify-content: space-between;
							padding: 20px 30px 20px 10px;
							margin: 0 20px;
							font-size: 14px;
							cursor: pointer;
							span:nth-child(2) {
								color: #d12;
							}
							span {
								i {
									color: #c5c5c5;
								}
							}
						}
						li:hover {
							background-color: #cef1d0;
						}
					}
				}
			}
			.demo-right {
				border-right: 2px solid #d7d7d7;
				flex: 6;
				position: relative;
				.el-steps {
					padding: 0px 200px 20px 60px;
				}
				.step {
					padding-right: 220px;
				}
				.dr-top {
					display: flex;
					margin: 0 20px;
					ul {
						display: flex;
						li {
							.dr-text {
								position: relative;
                height: 40px;
								cursor: pointer;
								background-color: #c9cfda;
								border-top-left-radius: 50px;
								border-bottom-left-radius: 50px;
								border-bottom-right-radius: 5px;
								border-top-right-radius: 5px;
								span {
									position: absolute;
									top: 0;
									left: -5px;
									display: inline-block;
									width: 30px;
									height: 30px;
									line-height: 28px;
									text-align: center;
									border-radius: 30px;
									border: 1px solid #67c23a;
									color: #000;
									font-size: 20px;
									background-color: #f0f9eb;
								}
								span:hover {
									background-color: #67c23a;
									color: #fff;
								}
								.activeDr {
									background-color: #67c23a;
									color: #fff;
								}
								p {
									margin: 0 0 0 45px;
									color: #000;
									font-size: 14px;
									width: 50px;
								}
							}
              i{
                font-size: 30px;
                margin: 5px 10px;
                color: #2eb135;
              }
						}
					}
				}
				.dr-content {
					margin-top: 20px;
					margin-left: 30px;
					h4 {
						font-size: 18px;
						margin: 25px 0;
					}
					h4::before {
						content: '*';
						color: #f56c6c;
						margin-right: 4px;
					}
					.change {
						margin-bottom: 20px;
						ul {
							li {
								display: inline-block;
								width: 90px;
								font-size: 14px;
								height: 40px;
								line-height: 40px;
								border: 1px solid #f5f5f5;
								margin: 10px;
								border-radius: 5px;
								text-align: center;
								cursor: pointer;
							}
							.activeChange {
								background-color: #409eff;
								color: #fff;
							}
						}
					}
					.el-checkbox-group {
						margin-top: 20px;
						.el-checkbox {
							margin: 10px 20px;
						}
					}
					::v-deep {
						.el-textarea {
							width: 529px;
						}
					}
					.requirements {
						margin-bottom: 40px;
						display: flex;
						align-items: center;
						margin-left: 40px;
						.el-select {
							width: 100%;
							padding-right: 30px;
						}
						span {
							padding-right: 20px;
							font-size: 14px;
						}
						::v-deep {
							.distpicker-address-wrapper select {
								width: 176px;
							}
							.distpicker-address-wrapper select:focus-visible {
								border-color: #e62d31;
							}
							.distpicker-address-wrapper select:focus {
								border-color: #e62d31;
							}
							.distpicker-address-wrapper label:nth-child(3) {
								display: none;
							}
						}
					}
					.content {
						.el-input {
							width: 400px;
						}
						.el-select {
							width: 400px;
							margin: 30px 0;
						}
						span {
							display: inline-block;
							width: 40px;
							height: 38px;
							text-align: center;
							line-height: 38px;
							cursor: pointer;
						}
						.activeLimit {
							background-color: #2eb135;
							color: #fff;
						}
						::v-deep {
							.el-input-group__append,
							.el-input-group__prepend {
								background-color: #fff;
								margin: 0;
								padding: 0;
							}
							.el-textarea {
								width: 400px;
							}
						}
						strong {
							font-weight: 400;
							width: 80px;
							display: inline-block;
							height: 40px;
							text-align: right;
							margin-right: 10px;
							font-size: 14px;
						}
						strong::before {
							content: '*';
							color: #e62d31;
							margin-right: 4px;
						}
					}
				}
				.dr-footer {
					position: absolute;
					bottom: 20px;
					left: 0;
					width: 100%;
					text-align: center;
					padding-right: 30px;
					.el-checkbox {
						margin-top: 20px;
						margin-left: 20px;
					}
					span {
						font-size: 12px;
						margin: 5px;
					}
					button {
						width: 150px;
						height: 40px;
						border: none;
						border-radius: 5px;
						background-color: #2eb135;
						color: #fff;
					}
					button:hover {
						background-color: #d12;
					}
				}
			}
		}
		.cat {
			background-color: #fff;
			height: 120px;
			box-shadow: 0 2px 10px 0 rgb(204 204 204 / 50%);
			border-radius: 5px;
			margin-top: 20px;
			ul {
				display: flex;
				li {
					flex: 1;
					display: flex;
					height: 120px;
					justify-content: center;
					align-items: center;
					a {
						text-align: center;
						p {
							font-size: 14.47px;
							color: #666666;
							text-align: center;
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	.excellent_recommend_title {
		text-align: center;
		margin-top: 60px;
		margin-bottom: 20px;
	}
	.see-more {
		text-align: center;
		margin-bottom: 60px;
    cursor: pointer;
	}
	.service {
		display: flex;
		border-radius: 5px;
		.left {
			flex: 1;
			background: #fff;
			border-radius: 5px;
			ul {
				overflow: hidden;
				li {
					display: flex;
					justify-content: space-around;
					height: 100px;
					align-items: center;
					padding: 0 20px;
					color: #2eb135;
					font-size: 25px;
					cursor: pointer;
					border-bottom: 1px solid #d7d7d7;
					img {
						height: 50px;
						width: 50px;
					}
				}
				li:last-child {
					border-bottom: none;
				}
				li:hover {
					background-color: #2eb135;
					color: #fff;
					transform: scale(1.2);
				}
				.active {
					background-color: #2eb135;
					color: #fff;
					border-radius: 5px;
					transform: scale(1.2);
				}
			}
		}
		.right {
			flex: 3;
			padding-left: 20px;
			.item {
				width: 281px;
				height: 190px;
				float: left;
				background: #fff;
				margin-right: 21px;
				margin-bottom: 20px;
				border-radius: 5px;
				position: relative;
				cursor: pointer;
				p {
					margin: 10px 20px 0 10px;
				}
				.see-detail {
					height: 30px;
					width: 80px;
					color: #2eb135;
					border-radius: 5px;
					border: 1px solid #2eb135;
					line-height: 30px;
					text-align: center;
					position: absolute;
					bottom: 70px;
					right: 20px;
				}
				.see-detail:hover {
					background-color: #71be6a;
					color: #fff;
				}
				.message {
					height: 50px;
					width: 251px;
					line-height: 50px;
					margin: 0 10px;
					border-top: 1px dashed #d7d7d7;
					position: absolute;
					bottom: 10px;
					left: 0;
					img {
						width: 26px;
						height: 26px;
						border-radius: 26px;
						margin: 0 5px;
					}
					span {
						font-size: 12px;
						color: #666666;
					}
				}
			}
			.item:hover {
				box-shadow: 10px 10px 10px 0 rgb(221 221 221 / 50%);
			}
			.item:nth-child(3),
			.item:nth-child(6) {
				margin-right: 0;
			}
			.item:nth-child(4),
			.item:nth-child(5),
			.item:nth-child(6) {
				margin-bottom: 0;
			}
		}
	}
	.demo-all {
		background-color: #fff;
		.demo-detail {
			margin-top: 20px;
			.hd {
				margin-top: 20px;
				ul {
					height: 110px;
					display: flex;
					justify-content: center;
					align-items: center;
					li {
						height: 50px;
						width: 210px;
						text-align: center;
						line-height: 50px;
						margin: 0 10px;
						cursor: pointer;
					}
					.activeHd {
						color: #fff;
						background-color: #2eb135;
						border-radius: 5px;
					}
				}
			}
			.bd {
				border-radius: 5px;
				padding: 20px;
				background-color: rgb(245, 245, 245, 1);
				.bd-item {
					margin-bottom: 20px;
					.bd-item-box {
						display: flex;
						cursor: pointer;
						position: relative;
						height: 150px;
						background-color: #fff;
						border-radius: 5px;
						align-items: center;
						margin-bottom: 20px;
						.bd-left {
							margin-left: 20px;
							img {
								width: 50px;
								height: 50px;
								border-radius: 50px;
							}
							.number {
								width: 50px;
								height: 20px;
								background-color: #d12;
								color: #fff;
								border-radius: 20px;
								text-align: center;
								margin-top: 10px;
							}
						}
						.detail {
							padding: 20px;
							width: 400px;
							.detail-top {
								span:nth-child(1) {
									margin-right: 20px;
								}
								span:nth-child(2) {
									color: #666666;
									font-size: 14px;
								}
							}
							.detail-midder {
								span {
									font-size: 14px;
									margin-right: 5px;
								}
							}
							.detail-footer {
								span {
									color: #666666;
									font-size: 14px;
								}
							}
							.detail-bottom {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								font-size: 14px;
							}
						}
						.bd-right {
							p {
								margin: 0;
								margin-left: 10px;
								font-size: 14px;
							}
						}
						::v-deep {
							.el-step__description.is-wait {
								width: 101px;
							}
						}
						.bd-btn {
							width: 80px;
							height: 30px;
							position: absolute;
							top: 30px;
							right: 30px;
							line-height: 30px;
							text-align: center;
							background-color: #2eb135;
							color: #fff;
							border-radius: 2px;
							z-index: 9999;
						}
					}
					.bd-sayHello-box {
						display: none;
						.box-top {
							height: 40px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							background-color: #cef1d0;
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;
							border-bottom: 1px solid #d7d7d7;
							span:nth-child(2) {
								cursor: pointer;
							}
						}
						.box-content {
							background-color: #fff;
							.content-top {
								display: flex;
								padding: 20px;
								.box-name {
									font-weight: 700;
								}
								.box-infomation {
									margin-left: 20px;
									ul {
										display: flex;
										li {
											margin: 0 5px;
											color: #666666;
										}
									}
								}
							}
							h4 {
								margin: 20px 0;
								text-align: center;
								font-size: 12px;
							}
							.inner {
								.chat-list {
									height: 300px;
									overflow-y: scroll;
									padding-bottom: 20px;
									.chat-item {
										padding: 10px;
										.chat-pao {
											vertical-align: top;
											display: inline-block;
											min-width: 40px;
											max-width: 70%;
											min-height: 40px;
											line-height: 38px;
											border: 0.5px solid #c2d9ea;
											border-radius: 4px;
											position: relative;
											padding: 0 10px;
											background-color: #e0effb;
											word-break: break-all;
											font-size: 14px;
											color: #333;
											&::before {
												content: '';
												width: 10px;
												height: 10px;
												position: absolute;
												top: 12px;
												border-top: 0.5px solid #c2d9ea;
												border-right: 0.5px solid #c2d9ea;
												background: #e0effb;
											}
										}
										.chat-pao.my {
											background-color: #9eea6a;
											&::before {
												content: '';
												background: #9eea6a;
											}
										}
									}
								}
								.chat-item.right {
									text-align: right;
									.chat-pao {
										margin-left: 0;
										margin-right: 15px;
										&::before {
											right: -6px;
											transform: rotate(45deg);
										}
									}
								}
								.chat-item.left {
									text-align: left;
									.chat-pao {
										margin-left: 15px;
										margin-right: 0;
										&::before {
											left: -5px;
											transform: rotate(-135deg);
										}
									}
								}
								.reply-container {
									height: 40px;
									width: 100%;
									background: #fff;
									span {
										display: inline-block;
										width: 80px;
										height: 38px;
										background-color: #2eb135;
										text-align: center;
										line-height: 38px;
										color: #fff;
										cursor: pointer;
									}
									::v-deep {
										.el-input-group__append {
											padding: 0;
										}
									}
								}
							}
						}
					}
					.bd-item-box:last-child {
						margin-bottom: 0;
					}
					.bd-item-box:hover {
						box-shadow: 0 1px 3px 0 rgb(206 210 214 / 60%);
					}
				}
			}
		}
	}
}
.contailer {
	width: 1200px;
	margin: auto;
}
</style>
